import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueSession from 'vue-session'
import VueSimpleAlert from 'vue-simple-alert'

import DatetimePicker from 'vuetify-datetime-picker'
import i18n from './i18n'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

// import ECharts modules manually to reduce bundle size
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent
} from 'echarts/components'

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent
]);

// register globally (or you can do it locally)
Vue.component('v-chart', ECharts)

Vue.use(DatetimePicker)
Vue.use(VueSimpleAlert)
Vue.prototype.axios = axios
Vue.prototype.emitter = new Vue()
axios.defaults.baseURL = 'https://rangalasystem.plustheedge.com'

//axios.defaults.baseURL = 'https://localhost:44351'

axios.defaults.headers.common['Authorization'] = 'bearer ' + store.state.token


Vue.use(VueSession)
new Vue({
    router,
    store,
    vuetify,
    axios,
    i18n,
    render: h => h(App)
}).$mount('#app')