<template>
  <v-app :style="$vuetify.dark?'background-color: rgb(241, 241, 241)':''">
    <navbar v-if="!print"/>
    <v-main>
      <transition name="scale" mode="out-in">
      <router-view/>
      </transition>
    <notification/>
    
    </v-main>


  </v-app>
</template>

<script>
import Navbar from "./components/navbar.vue";
import Overlay from "./components/Overlay.vue";
import Notification from './components/notification.vue';



export default {
  async created() {
    
  },
  data() {
    return {
      print: false,
    }
  },
  mounted() {
            this.emitter.$on('print', (data) => {
                this.print = data;
            });
        },
        beforeDestroy() {
            this.emitter.$off('print');
        },
  name: 'App',
  components: {
    Navbar,
    Notification,
    Overlay
  },
 

};
</script>
<style>
    tr th{
    font-size: 16px !important;
  }
  @font-face {
    font-family: NRT;
    src: url('./assets/fonts/NRT-Reg.ttf');
  }

  * {
    font-family: NRT;
  }
  tr td{
    font-size: 14px !important;
  }
  .scale-enter-from,
  .scale-leave-to {
    opacity: 0;
    transform: translateX(100%);
  }
  .scale-enter-active,
  .scale-leave-active {
    transition: all 0.3s ease-in;
  } 
</style>
