<template>
  <nav >
     <v-app-bar
      app
      dark
      elevation="2"
      rounded 
      class="rounded-t-0"
    >
    <v-app-bar-nav-icon v-if="isLoggedIn"  v-on:click="drawer = !drawer"></v-app-bar-nav-icon>
    
    <h2> ERP</h2>
    <v-spacer></v-spacer>
    <!-- <v-col class="mt-5" cols="12" sm="1" md="1" lg="1">    <v-select outlined dense  :items="['EN', 'KR', 'AR']"></v-select>
</v-col> -->
    
    
    <p class="mr-2 mt-3 ml-3">{{$session.get('username')}}</p>
    <v-menu offset-y  v-if="isLoggedIn">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <img
          v-if="employee.imageLink"
            :src=" axios.defaults.baseURL + '\\'+ employee.imageLink"
            alt="avatar"
            class="elevation-1"
          />
          <img
          v-else
            src="@/assets/images/default.png"
            alt="avatar"
            class="elevation-1"
          />
        </v-avatar>
      </template>
      <v-list class="mt-2">
        <v-list-item>
          <v-subheader>{{$t('navbar.language')}}</v-subheader>
          <v-col>
            
            <v-btn @click="lang = 'en'" x-small fab color="primary" class="" dense v-if="lang != 'en'">EN</v-btn>
            <v-btn @click="lang = 'kr'" x-small fab color="warning" class="" dense v-if="lang != 'kr'">KR</v-btn>
            <v-btn @click="lang = 'ar'" x-small fab color="success" class="" dense v-if="lang != 'ar'">AR</v-btn>
            <v-btn @click="lang = 'pr'" x-small fab color="success" class="" dense v-if="lang != 'pr'">PR</v-btn>
    </v-col>
        </v-list-item>
        <v-list-item>
          <v-row> <p class="mt-5 mr-3">{{$t('navbar.dark')}}</p> <v-switch class=" mr-3" v-model="dark"  color="success" @change="changeTheme()" hide-details ></v-switch></v-row>
        </v-list-item>
        <v-list-item>
          <v-list-item-title ><v-btn to="/profile" text><v-icon color="primary">mdi-account</v-icon>{{$t('navbar.profile')}}</v-btn> </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title ><v-btn text to="/changePassword"><v-icon color="primary">mdi-key</v-icon>  {{$t('navbar.changePassword')}}</v-btn></v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="logout()"><v-btn text @click="logout()"><v-icon color="error">mdi-logout</v-icon>{{$t('navbar.logout')}}</v-btn></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-app-bar> 
    <v-navigation-drawer v-if="isLoggedIn" hidden  app   v-model="drawer"   width="270" :right="this.$vuetify.rtl">
      <v-list nav >
        <v-row>
          <v-col></v-col>
          <v-col>
            <img
          src="@/assets/erp.png"
          width="100"
          height="100"
          />
          </v-col>
          <v-col></v-col>
        </v-row>
          <v-divider></v-divider>
        <v-list-item-group color="blue accent-2">
          <v-list-item link  to="/" class="scale" >  
            <v-list-item-icon>
                <v-icon> mdi-view-dashboard </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{$t('navbar.dashboard')}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item >
        <v-list-group no-action link >
          <template v-slot:activator>
            <v-list-item-icon>
                <v-icon> mdi-database</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('navbar.metaData')}}</v-list-item-title>
          </template>
            <v-list-item link to="/metadata/branches" class="scale pl-5">
                <v-list-item-icon>
                    <v-icon> mdi-source-branch</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{$t('navbar.branchandDepartment')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
        <v-list-group no-action link >
          <template v-slot:activator>
            <v-list-item-icon>
                <v-icon> mdi-database</v-icon>
            </v-list-item-icon>
            <v-list-item-title><span style="font-size:15px">{{$t('navbar.productAndService')}}</span></v-list-item-title>
          </template>
            <v-list-item link to="/productAndService/category" class="scale pl-5">
                <v-list-item-icon>
                    <v-icon> mdi-source-branch</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{$t('navbar.productCategory')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/productAndService" class="scale pl-5">
                <v-list-item-icon>
                    <v-icon> mdi-database</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title><span style="font-size:15px">{{$t('navbar.procuctsandservices')}}</span></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        
        
        
        
        </v-list-group>
       
          
        <!-- Human Resources -->
        <v-list-group no-action link v-if="$store.state.previlages.hr">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon> mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('navbar.hr')}}</v-list-item-title>
          </template>
          <!-- Employee -->
          <v-list-item class="pl-0" >
            <v-list-group no-action link sub-group >
              <template v-slot:activator  >
                <v-list-item-icon>
                  <v-icon> mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{$t('navbar.employee')}}</v-list-item-title>
              </template>
              <!-- Add Employee -->
              <v-list-item link to="/employee/addEmployee" class="scale pl-10" >
                <v-list-item-icon>
                  <v-icon> mdi-account-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t('navbar.addEmployee')}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- Views Employees -->
              <v-list-item left link to="/employees" class="scale pl-10" >
                <v-list-item-icon>
                  <v-icon > mdi-account-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t('navbar.viewEmployees')}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item>
          <!-- Official Letter -->
          <v-list-item link to="/forms/officialLetter"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.officialLetter')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- Accounting -->
        <v-list-group no-action link v-if="$store.state.previlages.accounting">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon> mdi-calculator</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('navbar.accounting')}}</v-list-item-title>
          </template>
          <!-- Payment Form -->
          <v-list-item link to="/forms/payment"  class="scale pl-5">
              <v-list-item-icon>
                <v-icon> mdi-form-select</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('navbar.payment')}}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <!-- All Payment -->
          <v-list-item link to="/allPayment" class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.allPayment')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Receipt -->
          <v-list-item link to="/forms/receipt"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.receipt')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- All Receipt -->
          <v-list-item link to="/allReceipt"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.allReceipt')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- All Accepted Advance & Loan -->
          <!-- <v-list-item link to="/allCashAdvance"  class="scale pl-5">
            <v-list-item-icon>
                <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{$t('navbar.allAdvanceLoan')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- Money Transfer -->
          <v-list-item link to="/forms/moneyTransfer"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.moneyTransfer')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Chart Of Account -->
          <v-list-item link to="/account" class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.account')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Transaction -->
          <v-list-item link to="/transaction" class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Transaction</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        
        <!-- Procurment -->
        <v-list-group no-action link v-if="$store.state.previlages.procurment">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon> mdi-truck</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('navbar.procurement')}}</v-list-item-title>
          </template>
          <!-- Supplier -->
          <v-list-item link to="/supplier" class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.supplier')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item class="ml-n15"> -->
            <v-list-item link to="/forms/customerPurchaseOrder"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.inventoryPO')}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
            <!-- <v-list-group no-action link sub-group > -->
          <!-- <template v-slot:activator>
            <v-list-item-icon>
              <v-icon> mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('navbar.inventoires')}} </v-list-item-title>
          </template> -->
          
            <!-- All Accepted Customer Purchase Request (Inventory PR) -->
          <!-- <v-list-item link to="/allCustomerPurchaseRequests"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.inventoryPR')}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- Customer Purchase Order (Inventory PO) -->
          
            <!-- </v-list-group> -->
          <!-- </v-list-item> -->

           <!-- <v-list-item class="ml-n15">
            <v-list-group no-action link sub-group >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon> mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('navbar.employees')}}</v-list-item-title>
          </template> -->
          <!-- All Accepted Purchase Request (Employees PR)-->
          <!-- <v-list-item link to="/allPurchaseRequests"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.employeesPR')}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- All Accepted  Purchase Ordder (Employees PO) -->
          <!-- <v-list-item link to="/allPurchaseOrder"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.employeesPO')}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          
          <!-- Internal Purchase Order -->
          <!-- <v-list-item link to="/forms/purchaseOrder"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.pO')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           </v-list-group>
           </v-list-item> -->

          <!-- Return Product From Customer (Returned Inventory) -->
          <v-list-item link to="/returnProductFromCustomer"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.returnedInventory')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
        </v-list-group>
        <!-- Sales -->
        <v-list-group no-action link v-if="$store.state.previlages.sales">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('navbar.sale')}}</v-list-item-title>
          </template>
          <!-- Customer Purchase Request -->
          <!-- <v-list-item link to="/forms/customerPurchaseRequest"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.customerPR')}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- Customers -->
          <v-list-item link to="/customer" class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.customer')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Invoice -->
          <v-list-item link to="/invoice"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.invoice')}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- All Invoice -->
          <v-list-item link to="/allInvoice"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.allInvoice')}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- All Invoice -->
          <v-list-item link to="/allCustomerProductReceive"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{'Customer Product Receive'}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Quotation -->
          <v-list-item link to="/quotation"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.quotation')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- All Quotation -->
          <v-list-item link to="/allQuotation"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.allQuotation')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Price List -->
          <v-list-item link to="/priceList"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.priceList')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- Warehouse -->
        <v-list-group no-action link v-if="$store.state.previlages.warehouse">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-store</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('navbar.warehouse')}}</v-list-item-title>
          </template>
          <!-- Inventory -->
          <!-- <v-list-item link to="/inventory"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.inventory')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- Customer Product Receive -->
          <v-list-item link to="/forms/customerProductReceive"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.inventoryProductReceive')}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           
          <!-- All Accepted Customer Purchase Ordder -->
          <v-list-item link to="/allCustomerPurchaseOrder"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.allCustomerPO')}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
        </v-list-group>
        <!-- Finance -->
          <v-list-group no-action link v-if="$store.state.previlages.finance">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon> mdi-finance</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('navbar.finance')}}</v-list-item-title>
          </template>
          <!-- Report -->
          <v-list-item link to="/finance/report" class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-chart-pie</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.report')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </v-list-group>
        <!-- General Forms -->
        <v-list-group no-action color="blue accent-2">
          <template v-slot:activator>
        <v-list-item-icon>
            <v-icon> mdi-form-dropdown</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{$t('navbar.forms')}}</v-list-item-title>
      </template>
          <v-list-item link to="/forms/overtime"  class="scale pl-5">
            <v-list-item-icon>
                <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{$t('navbar.overtime')}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
          <v-list-item link to="/forms/onlinework"  class="scale pl-5">
            <v-list-item-icon>
                <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{$t('navbar.onlineWork')}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
          
          <v-list-item link to="/forms/leave"  class="scale pl-5">
            <v-list-item-icon>
                <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{$t('navbar.leave')}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
          <v-list-item link to="/forms/resignation"  class="scale pl-5">
            <v-list-item-icon>
                <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{$t('navbar.resignation')}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
          <v-list-item link to="/forms/cashAdvance"  class="scale pl-5">
            <v-list-item-icon>
                <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{$t('navbar.advanceLoan')}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
          
          
          
          <v-list-item link to="/forms/travelApproval"  class="scale pl-5">
            <v-list-item-icon>
                <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{$t('navbar.travelApproval')}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/forms/purchaseRequest"  class="scale pl-5">
              <v-list-item-icon>
                  <v-icon> mdi-form-select</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                  <v-list-item-title>{{$t('navbar.purchaseRequest')}}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <!-- Product Receive -->
          <v-list-item link to="/forms/productReceive"  class="scale pl-5">
            <v-list-item-icon>
              <v-icon> mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('navbar.productReceive')}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
        </v-list-group>
         <!-- Shareholder -->
        <v-list-item link to="/shareholder"  v-if="$store.state.previlages.shareholder">
                <v-list-item-icon>
                    <v-icon> mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{$t('navbar.shareholder')}}</v-list-item-title>
                </v-list-item-content>
        </v-list-item>
        

        <v-list-group no-action link >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon> mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('navbar.supervisor')}}</v-list-item-title>
          </template>
          <!-- Border -->
        <v-list-item link to="/border" class="scale pl-5">
          <v-list-item-icon>
              <v-icon> mdi-database</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
              <v-list-item-title>{{$t('navbar.border')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Supervisor -->
        <v-list-item link to="/supervisor" class="scale pl-5">
          <v-list-item-icon>
              <v-icon> mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
              <v-list-item-title>{{$t('navbar.supervisor')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </v-list-group>









        <!-- Other Entity -->
        <v-list-item link to="/otherEntity" >
                <v-list-item-icon>
                    <v-icon> mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{$t('navbar.otherEntity')}}</v-list-item-title>
                </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item link to="/externalType" class="scale pl-5">
                <v-list-item-icon>
                    <v-icon> mdi-source-branch</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>External Type</v-list-item-title>
                </v-list-item-content>
            </v-list-item> -->
        

        <v-list-item link to="/settings" v-if="$store.state.previlages.setting">
                <v-list-item-icon>
                    <v-icon> mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{$t('navbar.settings')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>

export default {
    name: 'navbar',
    created(){
      
     if (this.isLoggedIn) {
        this.getProfile()
     }
     if(!localStorage.getItem('lang')){
                localStorage.setItem('lang', 'en');
            }
            if(localStorage.getItem('lang') == 'kr'){
              this.$vuetify.rtl = true;
            }else if(localStorage.getItem('lang') == 'ar'){
              this.$vuetify.rtl = true;
            }else if(localStorage.getItem('lang') == 'pr'){
              this.$vuetify.rtl = true;
            }
    },
 data: () => ({
    drawer: true,
    reports: false, 
    lang : localStorage.getItem('lang'),
      employee: {}
  }),
  methods: {
     logout(){
         this.$session.destroy()
         this.$store.commit('logout')
         this.$router.push('/login')
     },
     async getProfile(){
       const { data } = await this.axios.get(`/gettMyProfileeeee/${this.$session.get("id")}`);
            this.employee = data;
     },
     changeTheme() {
        this.$vuetify.theme.dark = !this.dark
      },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    dark() {
      return this.$vuetify.theme.dark
    },

  },
  watch: {
    lang(value) {
      this.$i18n.locale = value;
      localStorage.setItem('lang', value);
      if(value == 'kr' || value == 'ar' || value == 'pr'){
          this.$vuetify.rtl = true;
      } else {
          this.$vuetify.rtl = false;
      }
    }
  }
}
</script>

<style >
  a{
    text-decoration: none;
  }
  #ii:hover{
    transform: scale3d(1.4,1.4,1.4);
    transition-duration: 0.5s;
  }
  .scale:hover{
    transform: scale3d(1.1,1.1,1.1);
    transition-duration: 0.4s;
  }
   /* width */
  ::-webkit-scrollbar {
    width: 7px;
    
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background:rgb(133, 133, 133);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(133, 133, 133);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>