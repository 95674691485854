import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem('vue-session-key')?JSON.parse(sessionStorage.getItem('vue-session-key')).token?JSON.parse(sessionStorage.getItem('vue-session-key')).token:null:sessionStorage.setItem('vue-session-key', JSON.stringify({})),
    id:    sessionStorage.getItem('vue-session-key')?JSON.parse(sessionStorage.getItem('vue-session-key')).id?JSON.parse(sessionStorage.getItem('vue-session-key')).id:null:sessionStorage.setItem('vue-session-key', JSON.stringify({})),
    role:  sessionStorage.getItem('vue-session-key')?JSON.parse(sessionStorage.getItem('vue-session-key')).role?JSON.parse(sessionStorage.getItem('vue-session-key')).role:null:sessionStorage.setItem('vue-session-key', JSON.stringify({})),
  // token: JSON.parse(sessionStorage.getItem('vue-session-key')).token?JSON.parse(sessionStorage.getItem('vue-session-key')).token:null,
  // id:    JSON.parse(sessionStorage.getItem('vue-session-key')).id?JSON.parse(sessionStorage.getItem('vue-session-key')).id:null,
  // role:  JSON.parse(sessionStorage.getItem('vue-session-key')).role?JSON.parse(sessionStorage.getItem('vue-session-key')).role:null,
  previlages:sessionStorage.getItem('vue-session-key')?JSON.parse(sessionStorage.getItem('vue-session-key')).previlages?JSON.parse(sessionStorage.getItem('vue-session-key')).previlages:null:sessionStorage.setItem('vue-session-key', JSON.stringify({})),
  dark: false,
  },
  getters: {
    isLoggedIn: state => !!state.token,
    getId : state => state.id,
    getToken: state => state.token,
    gettRole: state => state.role,
    getDark: state => state.dark,
    getPrevilages: state => state.previlages,
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token
    },
    setId: (state, id) => {
      state.id = id
    },
    setPrevilage : (state, previlages) => {
      console.log(previlages);
      state.previlages = previlages
    },
    logout(state){
      state.token = null
      state.id = null
      state.previlages = null
    },
    changeTheme(state) {
      state.dark = !state.dark
    }

  },
  actions: {
  },
  modules: {
  }
})
