import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'




Vue.use(VueRouter)

const routes = [
  {
    path: '/accounting',
    name: 'Accounting',
    component: () => import( '../views/Accounting.vue'),
    children: [
      {
        path: '/allPayment',
        name: 'AllPayment',
        component: () => import('../views/AllPayment/AllPayment.vue'),
        
      },
      {
        path: '/forms/moneyTransfer',
        name: 'MoneyTransfer',
        component: () => import('../views/Forms/MoneyTransfer/MoneyTransfer.vue'),
        
      },
      {
        path: '/forms/moneyTransfer/print/:id',
        name: 'PrintMoneyTransfer',
        component: () => import('../views/Forms/MoneyTransfer/PrintMoneyTransfer.vue'),
        
      },
      {
        path: '/forms/payment',
        name: 'Payment',
        component: () => import('../views/Forms/Payment/Payment.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/forms/Payment/print/:id',
        name: 'PrintPayment',
        component: () => import('../views/Forms/Payment/PrintPayment.vue'),
        
      },
      {
        path: '/forms/receipt',
        name: 'Receipt',
        component: () => import('../views/Forms/Receipt/Receipt.vue'),
        
      },
      {
        path: '/allReceipt',
        name: 'AllAcceptedReceipt',
        component: () => import('../views/Forms/Receipt/AllAcceptedRecipt.vue'),
        
      },
      {
        path: '/receipt/print/:id',
        name: 'PrintReceipt',
        component: () => import('../views/Forms/Receipt/PrintReceipt.vue'),
        
      },
      {
        path: '/allCashAdvance',
        name: 'AllAcceptedAdvance',
        component: () => import('../views/Forms/CashAdvance/AllAcceptedAdvance.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import('../views/Account/Account.vue'),
        
      },
      {
        path: '/account/:id/:name',
        name: 'ViewAccount',
        component: () => import('../views/Account/ViewAccount.vue'),
        
      },
    ],
    meta: {requiresAuth: true, previlage: 'accounting'},
  },
  {
    path: '/hr',
    name: 'HR',
    component: () => import( '../views/HR.vue'),
    children: [
      {
        path: '/employee/addEmployee',
        name: 'AddEmployee',
        component: () => import('../views/Employee/AddEmployee.vue'),
      },
      {
        path: '/employee/viewEmployee/:id/:name',
        name: 'ViewEmployee',
        component: () => import('../views/Employee/ViewEmployee.vue'),
      },
      {
        path: '/employee/employeeReport/:id/:name',
        name: 'EmployeeReport',
        component: () => import('../views/Employee/EmployeeReport.vue'),
      },
      {
        path: '/employees',
        name: 'ViewEmployees',
        component: () => import('../views/Employee/ViewEmployees.vue'),
        meta: {requiresAuth: true, requiresHr: true}
      },
      {
        path: '/forms/officialLetter',
        name: 'OfficialLetter',
        component: () => import('../views/Forms/OfficialLetter/OfficialLetter.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/forms/officialLetter/print/:id',
        name: 'PrintOfficialLetter',
        component: () => import('../views/Forms/OfficialLetter/PrintOfficialLetter.vue'),
        meta: {requiresAuth: true},
      },
    ],
    meta: {requiresAuth: true, previlage: 'hr'},
  },
  {
    path: '/procurment',
    name: 'Procurment',
    component: () => import( '../views/Procurment.vue'),
    children: [
      {
        path: '/supplier',
        name: 'Supplier',
        component: () => import('../views/Supplier/Supplier.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/supplier/:id/:name',
        name: 'ViewSupplier',
        component: () => import('../views/Supplier/ViewSupplier.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/allPurchaseRequests',
        name: 'AllPurchaseRequests',
        component: () => import('../views/Forms/PurchaseRequest/AllAcceptedPurchaseRequests.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/allCustomerPurchaseRequests',
        name: 'AllCustomerPurchaseRequests',
        component: () => import('../views/Forms/CustomerPurchaseRequest/AllAcceptedCustomerPurchaseRequests.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/allPurchaseOrder',
        name: 'AllPurchaseOrder',
        component: () => import('../views/Forms/InternalPurchaseOrder/AllAcceptedInternalPurchaseOrders.vue'),
        meta: {requiresAuth: true},
      },
      
      {
        path: '/forms/purchaseOrder',
        name: 'PurchaseOrder',
        component: () => import('../views/Forms/InternalPurchaseOrder/InternalPurchaseOrder.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/forms/purchaseOrder/print/:id',
        name: 'PrintPurchaseOrder',
        component: () => import('../views/Forms/InternalPurchaseOrder/PrintInternalPurchaseOrder.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/returnProductFromCustomer',
        name: 'ReturnedProductFromCustomer',
        component: () => import('../views/Forms/ReturnProductFromCustomer/ReturnProductFromCustomer.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/returnProductFromCustomer/print/:id',
        name: 'PrintReturnedProductFromCustomer',
        component: () => import('../views/Forms/ReturnProductFromCustomer/PrintReturnProductFromCustomer.vue'),
        meta: {requiresAuth: true},
      },
      
    ],
    meta: {requiresAuth: true, previlage: 'procurment'},
  },
  {
    path: '/sales',
    name: 'Sales',
    component: () => import( '../views/Sales.vue'),
    children: [
      {
        path: '/forms/customerPurchaseRequest',
        name: 'CustomerPurchaseRequest',
        component: () => import('../views/Forms/CustomerPurchaseRequest/CustomerPurchaseRequest.vue'),      },
      {
        path: '/forms/customerPurchaseRequest/print/:id',
        name: 'PrintCustomerPurchaseRequest',
        component: () => import('../views/Forms/CustomerPurchaseRequest/PrintCustomerPurchaseRequest.vue'),
      },
      {
        path: '/priceList',
        name: 'PrintCustomerPurchaseRequest',
        component: () => import('../views/PriceList.vue'),
      },
      {
        path: '/invoice',
        name: 'Invoice',
        component: () => import('../views/Forms/Invoice/Invoice.vue'),
      },
      {
        path: '/invoice/print/:id',
        name: 'PrintInvoice',
        component: () => import('../views/Forms/Invoice/PrintInvoice.vue'),
      },
      {
        path: '/allInvoice',
        name: 'AllAcceptedInvoice',
        component: () => import('../views/Forms/Invoice/AllAcceptedInvoice.vue'),
      },
      {
        path: '/allCustomerProductReceive',
        name: 'CustomerProductReceiveForInvoice',
        component: () => import('../views/Forms/Invoice/CustomerProductReceive.vue'),
      },
      {
        path: '/quotation',
        name: 'Quotation',
        component: () => import('../views/Forms/Quotation/Quotation.vue'),
      },
      {
        path: '/quotation/print/:id',
        name: 'PrintQuotation',
        component: () => import('../views/Forms/Quotation/PrintQuotation.vue'),
      },
      {
        path: '/allQuotation',
        name: 'AllApprovedQuotation',
        component: () => import('../views/Forms/Quotation/AllQuotation.vue'),
      },
      {
        path: '/customer',
        name: 'Customer',
        component: () => import('../views/Customer/Customer.vue'),
      },
      {
        path: '/customer/:id/:name',
        name: 'ViewCustomer',
        component: () => import('../views/Customer/ViewCustomer.vue'),
      },
    ],
    meta: {requiresAuth: true, previlage: 'sales'},
  },
  {
    path: '/warehouse',
    name: 'Warehouse',
    component: () => import( '../views/Warehouse.vue'),
    children: [
      {
        path: '/forms/customerProductReceive',
        name: 'CustomerProductReceive',
        component: () => import('../views/Forms/CustomerProductReceive/CustomerProductReceive.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/forms/customerProductReceive/print/:id',
        name: 'PrintCustomerProductReceive',
        component: () => import('../views/Forms/CustomerProductReceive/PrintCustomerProductReceive.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/allCustomerPurchaseOrder',
        name: 'AllCustomerPurchaseOrder',
        component: () => import('../views/Forms/CustomerPurchaseOrder/AllAcceptedCustomerPurchaseOrders.vue'),
        meta: {requiresAuth: true},
      },
    ],
    meta: {requiresAuth: true, previlage: 'warehouse'},
  },
  {
    path: '/forms/productReceive',
    name: 'InternalProductReceive',
    component: () => import('../views/Forms/InternalProductReceive/InternalProductReceive.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/forms/productReceive/print/:id',
    name: 'PrintInternalProductReceive',
    component: () => import('../views/Forms/InternalProductReceive/PrintInternalProductReceive.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/metadata/branches',
    name: 'Branches',
    component: () => import('../views/MetaData/Branches.vue'),
    meta: {requiresAuth: true, requiresHr: true}
  },
  {
    path: '/metadata/departments/:id/:name',
    name: 'Departments',
    component: () => import('../views/MetaData/Departments.vue'),
    meta: {requiresAuth: true, requiresHr: true},
  },
  
  
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/productAndService',
    name: 'ProductAndService',
    component: () => import('../views/ProductAndService/ProductAndService.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/productAndService/category',
    name: 'ProductAndServiceCategory',
    component: () => import('../views/ProductAndService/Category.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/productAndService/:id/:name',
    name: 'ViewProductAndService',
    component: () => import('../views/ProductAndService/ViewProductAndService.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: {requiresAuth: true}
  },
  
 
  {
    path: '/forms/overtime',
    name: 'OverTime',
    component: () => import('../views/Forms/OverTime/OverTime.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/forms/overtime/print/:id',
    name: 'PrintOvertime',
    component: () => import('../views/Forms/OverTime/PrintOverTime.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/forms/onlinework',
    name: 'OnlineWork',
    component: () => import('../views/Forms/OnlineWork/OnlineWork.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/forms/onlinework/print/:id',
    name: 'PrintOnlineWork',
    component: () => import('../views/Forms/OnlineWork/PrintOnlineWork.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/forms/leave',
    name: 'Leave',
    component: () => import('../views/Forms/Leave/Leave.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/forms/leave/print/:id',
    name: 'PrintLeave',
    component: () => import('../views/Forms/Leave/PrintLeave.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/forms/resignation',
    name: 'Resignation',
    component: () => import('../views/Forms/Resignation/Resignation.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/forms/resignation/print/:id',
    name: 'PrintResignation',
    component: () => import('../views/Forms/Resignation/PrintResignation.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/forms/cashAdvance',
    name: 'CashAdvance',
    component: () => import('../views/Forms/CashAdvance/CashAdvance.vue'),
    meta: {requiresAuth: true}
  },
  
  {
    path: '/forms/cashAdvance/print/:id',
    name: 'PrintCashAdvance',
    component: () => import('../views/Forms/CashAdvance/PrintCashAdvance.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/forms/purchaseRequest',
    name: 'PurchaseRequest',
    component: () => import('../views/Forms/PurchaseRequest/PurchaseRequest.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/forms/purchaseRequest/print/:id',
    name: 'PrintPurchaseRequest',
    component: () => import('../views/Forms/PurchaseRequest/PrintPurchaseRequest.vue'),
    meta: {requiresAuth: true},
  },
  
  
  {
    path: '/forms/customerPurchaseOrder',
    name: 'CustomerPurchaseOrder',
    component: () => import('../views/Forms/CustomerPurchaseOrder/CustomerPurchaseOrder.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/forms/customerPurchaseOrder/print/:id',
    name: 'PrintCustomerPurchaseOrder',
    component: () => import('../views/Forms/CustomerPurchaseOrder/PrintCustomerPurchaseOrder.vue'),
    meta: {requiresAuth: true},
  },
  
  
  
  {
    path: '/forms/travelApproval',
    name: 'TravelApproval',
    component: () => import('../views/Forms/TravelApproval/TravelApproval.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/forms/TravelApproval/print/:id',
    name: 'PrintTravelApproval',
    component: () => import('../views/Forms/TravelApproval/PrintTravelApproval.vue'),
    meta: {requiresAuth: true},
  },
  
 
  
  {
    path: '/externalType',
    name: 'ExternalType',
    component: () => import('../views/ExternalType.vue'),
    meta: {requiresAuth: true},
  },
  
  
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('../views/Forms/CustomerPurchaseOrder/AllAcceptedCustomerPurchaseOrders.vue'),
    meta: {requiresAuth: true},
  },
 
  
  
  
  {
    path: '/finance/report',
    name: 'FinanceReport',
    component: () => import('../views/Finance/Report.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/shareholder',
    name: 'Shareholder',
    component: () => import('../views/Shareholder/Shareholder.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/shareholder/:id/:name',
    name: 'ViewShareholder',
    component: () => import('../views/Shareholder/ViewShareholder.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/supervisor',
    name: 'Supervisor',
    component: () => import('../views/Supervisor/Supervisor.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/supervisor/:id/:name',
    name: 'ViewSupervisor',
    component: () => import('../views/Supervisor/ViewSupervisor.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/border',
    name: 'Border',
    component: () => import('../views/Border/Border.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/otherEntity',
    name: 'OtherEntity',
    component: () => import('../views/OtherEntity/OtherEntity.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/otherEntity/:id/:name',
    name: 'ViewOtherEntity',
    component: () => import('../views/OtherEntity/ViewOtherEntity.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/transaction',
    name: 'Transaction',
    component: () => import('../views/Transaction/AllTransaction.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.token ) {
      next({
        path: '/login',
      })
    } else {
      if(to.matched.some(record => record.meta.previlage)){
        switch (to.matched[0].meta.previlage) {
          case 'accounting':
              if (store.state.previlages.accounting) {
                next()
              } else {
                next({
                  path: '/',
                })
              }
          break;
          case 'hr':
            if (store.state.previlages.hr) {
              next()
            } else {
              next({
                path: '/',
              })
            }
          break;
          case 'procurment':
            if (store.state.previlages.procurment) {
              next()
            } else {
              next({
                path: '/',
              })
            }
          break;
          case 'sales':
            if (store.state.previlages.sales) {
              next()
            } else {
              next({
                path: '/',
              })
            }
          break;
          case 'warehouse':
            if (store.state.previlages.warehouse) {
              next()
            } else {
              next({
                path: '/',
              })
            }
          break;
        
          default:
            break;
        }
      }else{
        next()
      }
    }
  } else {
    next()
  }
})

export default router
