<template>
  <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</template>

<script>
export default {
    data() {
        return {
            overlay: false
        }
    },
    mounted() {
            this.emitter.$on('onOverlay', () => {
                this.overlay = true
            });
            this.emitter.$on('offOverlay', () => {
                this.overlay = false
            });
        },
        beforeDestroy() {
            this.emitter.$off('onOverlay');
            this.emitter.$off('offOverlay');
        }
}
</script>

<style>

</style>